import React from 'react';
import SocialNetwork from '../social-networks/social-networks';
import './footer.scss';

export default function Footer({ dataFooter, className, isMapPage = false }) {

  const {
    logo,
    instagramUrl,
    facebookUrl,
    tiktokUrl,
    twitterUrl,
    youtubeUrl,
    tieneYoutube, 
    tieneInstagram, 
    tieneFacebook, 
    tieneTikTok,
    tieneTwitter,
    copyright,
    customerService,
    politicasDeProteccion
  } = dataFooter;

  const socialNetwork = [
    {url: instagramUrl, isVisible: tieneInstagram}, 
    {url: facebookUrl, isVisible: tieneFacebook}, 
    {url: tiktokUrl, isVisible: tieneTikTok}, 
    {url: twitterUrl, isVisible: tieneTwitter}, 
    {url: youtubeUrl, isVisible: tieneYoutube}, 
  ];
  return (
    <footer className={className}>
      <div className="f-footer-container">
        <div className={isMapPage ? "f-footer-content f-footer-content--full" : "f-footer-content"}>
          <div className="f-logo-container">
            <img
              className="f-logo"
              alt="imagen logo footer"
              src={logo.file.url}
            ></img>
          </div>

          <div className="f-copyright-customer-terminos-social">
            <div className="f-copyright-customer-terminos">
              <div className="f-copyright-customer">
                <div className="f-copyrigth">{copyright}</div>
                <div className="f-separator">{process.env.LOCALE === "es-DO" || process.env.LOCALE === "es-PR" ? " " : "/"}</div>
                <div className="f-customerService">{customerService}</div>
              </div>

              <div className="f-terminos">
                <a href={"/terminos-y-condiciones"} className={"nostyle"}>
                  <label>TÉRMINOS Y CONDICIONES</label>
                </a>
                <a
                  href={politicasDeProteccion.file.url}
                  target="_blank"
                  className="nostyle"
                  rel="noopener"
                >
                  <label className="f-data">TRATAMIENTO DATOS</label>
                </a>
              </div>
            </div>

            <SocialNetwork socialNetworks={socialNetwork}></SocialNetwork>
          </div>
        </div>
      </div>
    </footer>
  );
}
