import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import ImgShopLocal from '../../../shared//assets/images/productos/familia-local-shops.png';
import ImgShopLocalMobile from '../../../shared//assets/images/productos/familia-local-shops-mobile.png';
import { pushDataLayerEvent, pushGtagEvent } from '../../../shared/utils/metricManagementUtil';
import './shop-modal.scss';

const ShopModal = props => {
  const { setmodalActive, commercialPartners, isLocalShop = false, productData = null } = props;
  const { isMobile } = useMobileMode();
  const resultQuery = useStaticQuery(
    graphql`
      query SHOPS_QUERY {
        allContentfulPagina(filter: { slug: { eq: "tiendas/" } }) {
          nodes {
            node_locale
            mainTitle
            description {
              json
            }
            banner {
              file {
                url
              }
              description
            }
            stores {
              title
              storeRedirection
              storeLogo {
                description
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const infoModal = resultQuery.allContentfulPagina.nodes.filter(
    e => e.node_locale === process.env.LOCALE
  )[0];
  const bannerInfo = infoModal?.banner[0];
  const stores = infoModal?.stores;

  const gTagSelectItem = (optionSelected) => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    if(productData) {
      const {id, productName, commercialPartner, characteristics, productPresentations, contentful_id, metaTitle, category} = productData
      pushGtagEvent({
        event: 'add_to_cart',
        params: {
          ecommerce:{
            currency: 'COP',
            items: [{
              item_id: id,
              item_name: productName?.productName||'',
              coupon: 'Ninguno',
              discount: '0',
              affiliation: commercialPartner ? commercialPartner : '',
              item_brand: 'Familia',
              item_category: 'Productos',
              item_variant: characteristics ? characteristics[0].title : '',
              price: '0',
              currency: 'COP',
              quantity: '0',
            }]
          },
          value: '0',
          option: optionSelected,
          units: productPresentations ? `X${productPresentations[0].quantity}` : '',
          qualification: productData.ratingValue ? `${productData.ratingValue}` : '0' ,
          user_data: [],
          log_status: '',
        }
      })
      pushDataLayerEvent({
        event: 'buy_now_go_to_retailer',
        retailer: optionSelected,
        single_item_id: contentful_id,
        single_item_name: category.title?.title + ' ' + productName?.productName
      })
      pushDataLayerEvent({
        event: 'pop_up',
        action_type: 'click',
        label: 'Tiendas aliadas',
        single_item_id: contentful_id,
        single_item_name: category.title?.title + ' ' + productName?.productName,
        link_text: optionSelected,
        link_url: location,
        link_domain: 'familia.com.co'
      })
    }
  }

  const datalayerCloseShopModal = () => {
    let location = '';
    if (typeof window !== 'undefined') {
      location = window.location.href;
    }
    if(productData){
      const {contentful_id, category, productName} = productData
      pushDataLayerEvent({
        event: 'pop_up',
        action_type: 'close',
        label: 'Tiendas aliadas',
        single_item_id: contentful_id,
        single_item_name: category.title?.title + ' ' + productName?.productName,
        link_text: 'Icono x',
        link_url: location,
        link_domain: 'familia.com.co'
      })
    }
  }

  return (
    <div className="f-shop-modal-curtain">
      {isLocalShop ? (
        <div className="f-shop-modal-container f-shop-modal-container--no-bg">
          <span
            className="f-close-modal-button"
            onClick={() => {setmodalActive(null); datalayerCloseShopModal()}}
          />

          <img
            className="f-shop-modal-local-img"
            src={isMobile ? ImgShopLocalMobile : ImgShopLocal}
            alt="compralos en tu tienda más cercana"
          />
        </div>
      ) : (
        <div className="f-shop-modal-container">
          <span
            className="f-close-modal-button"
            onClick={() => {setmodalActive(null); datalayerCloseShopModal()}}
          />
          {isMobile ? (
            <div className="f-banner-container-mobile">
              <img
                className="f-shop-modal-banner"
                src={bannerInfo.file.url}
                alt={bannerInfo.description}
              />
            </div>
          ) : (
            <img
              className="f-shop-modal-banner"
              src={bannerInfo?.file.url}
              alt={bannerInfo?.description}
            />
          )}

          <div className="f-right-section-container">
            <div className="f-title-container">
              <span className="f-icon-container"></span>
              <span className="f-title-modal">{infoModal?.mainTitle}</span>
            </div>
            <div className="f-subtitle-modal">
              {documentToReactComponents(infoModal?.description.json)}
            </div>
            <div className="f-stores-container">
              {stores?.map((store, index) => {
                return (
                  <a
                    className="f-store-card"
                    href={
                      commercialPartners &&
                      commercialPartners[index] &&
                      commercialPartners[index] !== 'contenido-oculto'
                        ? commercialPartners[index]
                        : store.storeRedirection
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    onClick={() =>{
                      gTagSelectItem(store.title)
                      window.dataLayer = window.dataLayer || []; 
                      window.dataLayer.push({
                        'event':'Interacciones - FamiCalculadora',
                        'category':'Famicalculadora',
                        'action':'Click',
                        'label': store.title
                      });
                    }}
                  >
                    <img
                      className="f-img-store"
                      src={store.storeLogo.file.url}
                      alt={store.storeLogo.description}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopModal;
