import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { axiosInstance } from '../../../services/axios-instance';
import './cookie-consent.scss'

const CookieConsent = ({ logoUrl }) => {
    const [popupState, setPopupState] = useState(false)
    const [cookies, setCookie] = useCookies();
    const urlService = process.env.ENVIRONMENT === 'PDN' ? 'https://2blh4fqkq6.execute-api.us-east-1.amazonaws.com/pdn/familia/transversal/api/v1/cookie-policy' :
        'https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev/familia/transversal/api/v1/cookie-policy';


    const onAccept = () => {
        const actualDate = new Date()
        actualDate.setFullYear(actualDate.getFullYear() + 1)


        let payload = {
            accept: true,
            brandName: "FAMILIA"
        }

        setCookie("cookieconsent_status", true, { expires: actualDate, path: "/", priority: "High" })
        setPopupState(false)
        axiosInstance.post(urlService, payload).finally(() => {
        })
    }

    useEffect(() => {
        if (!cookies.cookieconsent_status) {
            setPopupState(true)
        }
    }, [])

    return (
        <div className={"f-cookie-conset-container" + (popupState ? " f-cookie-conset-container--active" : '')}>
            <div className="f-cookie-conset">
                <figure>
                    <img src={logoUrl} alt="logo" />
                </figure>
                <div className="f-cookie-conset__content">
                    <h3 className="f-cookie-conset__content__title">Este sitio web utiliza cookies</h3>
                    <p className="f-cookie-conset__content__description">
                        Dependemos de las cookies para recordar tus preferencias y
                        analizar el tráfico de nuestro sitio web. Permites nuestras
                        cookies al hacer clic en "Aceptar"
                    </p>
                </div>
                <div className="f-cookie-conset__actions">
                    <button onClick={onAccept}>Aceptar</button>
                    <a href="/terminos-y-condiciones/">Ver más</a>
                </div>
            </div>
        </div>
    )
}

export default CookieConsent
