// APIs
const url = process.env.ENVIRONMENT === 'PDN' ? 'https://2blh4fqkq6.execute-api.us-east-1.amazonaws.com/pdn' : 'https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev';

// Roullete Data Recovery service from Marketing Cloud
export const urlRoulleteGetToken = "https://mc2-jhspf7rtc04zsr556sp2cyhm.auth.marketingcloudapis.com/v2/token"
export const urlRoulleteSentForm = "https://mc2-jhspf7rtc04zsr556sp2cyhm.rest.marketingcloudapis.com/data/v1/async/dataextensions/key:Familia_API_Landing_Ruleta/rows"


// API Google Key
export const API_GOOGLE_KEY = 'AIzaSyCN8ZrJwdNr5hJ4FkpgwMR6qzH1KS4TWlg';

// rating
export const rating = url + '/familia/rating/api/v1/';

// notification
export const contact = url + '/familia/notification/api/v1/send/email';

//Magazin
export const likes = url + '/familia/content/api/v1/like';

//Calculator
export const calculatorInfo = url + '/familia/loyalty/api/v1/alert';

// Roulette
export const participation = url + '/familia/register/api/v1/user';
export const competitionParticipation = url + '/familia/competition/api/v1/competition-participation';
export const getPrize = url + '/familia/competition/api/v1/prize/probability';
export const savePrize = url + '/familia/competition/api/v1/prize';

//WebToCase Variables
export const WebToCaseUrl = process.env.ENVIRONMENT === 'STG' ? 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8' :
  process.env.ENVIRONMENT === 'PDN' ? 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8' :
    'https://grupofamilia--gpofamdev.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
export const ORGID = process.env.ENVIRONMENT !== 'PDN' ? '00D3j0000001yNQ' : '00D3j0000001yNQ';
export const RECORDTYPE = process.env.ENVIRONMENT !== 'PDN' ? '0123j000000X9zrAAC' : '0123j000000X9zrAAC';

// Get ip Address
export const GET_IP_ADDRESS = 'https://jsonip.com/';