import React from 'react';
import './header.scss';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const Header = ({ dataHeader }) => {

  const { logo } = dataHeader;

  const datalayerImgHeader = () => {
    pushDataLayerEvent({
      event: 'menu_click',
      action_type: 'Link',
      link_text: 'familia > Ninguno',
      index: '1',
      menu_name: 'Menu principal'
    })
  }

  return (
    <header className="f-header-container">
      <div className="f-header-content">
        <a href={"/"} className={"nostyle"} onClick={() => datalayerImgHeader()}>
          <div className="f-white-tab">
            <img
              className="f-logo"
              alt="imagen logo header"
              src={logo.file.url}
            ></img>
          </div>
        </a>
      </div>
    </header>
  );
};

export default Header;