import axios from "axios";
import * as apiConstants from "../shared/constants/api-constants";

export const axiosInstance = axios.create();

function getIp() {
  return new Promise((resolve, reject) => {
    axios.get(apiConstants.GET_IP_ADDRESS).then((response) => {
      sessionStorage.setItem("ipAddress", response.data.ip)
      resolve(response.data.ip);
    }).catch((error) => {
      sessionStorage.setItem("ipAddress", "127.0.0.1");
      resolve("127.0.0.1");
    });
  });
}

axiosInstance.interceptors.request.use(async (config) => {
  const tokenResponse = await getIp();
  config.headers['Ip-Address'] = sessionStorage.getItem("ipAddress") ? sessionStorage.getItem("ipAddress") : tokenResponse;
  return config;
}, (error) => {
  return Promise.reject(error);
});