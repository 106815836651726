import React, { useState } from 'react';

export default function useMobileMode() {
  const [isMobile, setMobileBool] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState('');
  const [valueWidth, setvalueWidth] = useState(0)

  const updateDimensions = () => {
    setvalueWidth(window.innerWidth)
    setMobileBool(window.innerWidth < 1024);
  };

  React.useEffect(() => {
    updateDimensions();
    // if (currentPage && currentPage !== 'productos') {
    //   setCurrentPage('productos');
    // }
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return { isMobile, currentPage, valueWidth };
}
