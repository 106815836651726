import { getSessionStorage, getLocalStorage } from './utilities-dom';

const _sessionStorage = getSessionStorage();
const _localStorage = getLocalStorage();

export class SessionStorage {
  static getItem(key) {
    const item = _sessionStorage.getItem
      ? _sessionStorage.getItem(key)
      : undefined;
    try {
      return JSON.parse(item);
    } catch {
      return item;
    }
  }
  static setItem(key, value) {
    if (typeof value === 'object') {
      _sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      _sessionStorage.setItem(key, value);
    }
    return true;
  }

  static removeItem(key) {
    _sessionStorage.removeItem(key);
  }

  static clear() {
    _sessionStorage.clear();
  }
}

export class LocalStorage {
  static getItem(key) {
    const item = _localStorage.getItem ? _localStorage.getItem(key) : undefined;
    try {
      return JSON.parse(item);
    } catch {
      return item;
    }
  }

  static setItem(key, value) {
    if (typeof value === 'object') {
      _localStorage.setItem(key, JSON.stringify(value));
    } else {
      _localStorage.setItem(key, value);
    }
    return true;
  }
  
  static removeItem(key) {
    _localStorage.removeItem(key);
  }

  static clear() {
    _localStorage.clear();
  }
}
