import React from 'react';
import './social-networks.scss';
import tiktokImg from '../../../assets/images/iconTiktok.png'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

export default function SocialNetwork(props) {
  const { socialNetworks } = props;

  const datalayerSocialNetworksMenu = (label, index) => {
    pushDataLayerEvent({
      event: 'menu_click',
      action_type: 'Link',
      link_text: label+' > Ninguno',
      index: index,
      menu_name: 'Menu principal'
    })
  }
  return (
    <div className="f-social-container">
      {socialNetworks[0].isVisible ? <a href={socialNetworks[0].url} target="_blank" rel="noopener" onClick={() => datalayerSocialNetworksMenu('Instagram', 8)}>
        <i className={`fab fa-2x fa-instagram`}></i>
      </a> : null}
      {socialNetworks[1].isVisible ? <a href={socialNetworks[1].url} target="_blank" rel="noopener" onClick={() => datalayerSocialNetworksMenu('Facebook', 9)}>
        <i className={`fab fa-2x fa-facebook`}></i>
      </a> : null}
      {socialNetworks[2].isVisible ? <a href={socialNetworks[2].url} target="_blank" rel="noopener" onClick={() => datalayerSocialNetworksMenu('TikTok', 10)}>
      <img className='f-social-img' src={tiktokImg} alt="Imagen tiktok" />
      </a> : null}
      {socialNetworks[3].isVisible ? <a href={socialNetworks[3].url} target="_blank" rel="noopener" onClick={() => datalayerSocialNetworksMenu('Twitter', 11)}>
        <i className={`fab fa-2x fa-twitter`}></i>
      </a> : null}
      {socialNetworks[4].isVisible ? <a href={socialNetworks[4].url} target="_blank" rel="noopener" onClick={() => datalayerSocialNetworksMenu('Youtube', 12)}>
        <i className={`fab fa-2x fa-youtube`}></i>
      </a> : null}
    </div>
  );
}
