const printParams = params => {
    for (const option in params) {
      console.log(option, params[option])
    }
  }
  
  const pushDataLayerEvent = params => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(params)
    
    if (process.env.ENVIRONMENT !== "PDN") {
      console.log("===PUSH  DATALAYER")
      printParams(params)
      console.log("===PUSH  DATALAYER")
    }
  }
  
  const pushGtagEvent = ({ event, params }) => {
    if(typeof window!=='undefined'){
    window.gtag = window.gtag || function() {}
    window.gtag("event", event, params)
    
    if (process.env.ENVIRONMENT !== "PDN") {
      console.log('=== GA EVENT: ', event)
      printParams(params)
      console.log('=== GA EVENT: ', event)
    }}
  }
  
  export { pushDataLayerEvent, pushGtagEvent }
  