import React from 'react';
import { Link } from 'gatsby';

const CustomLink = React.forwardRef(
  ({ isAnchor, to, children, ...props }, ref) => {
    return isAnchor ? (
      <a href={to} ref={ref} {...props}>
        {children}
      </a>
    ) : (
      <Link to={to} ref={ref} {...props}>
        {children}
      </Link>
    );
  }
);

export default CustomLink;
