
import React, { useState } from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import './popUp-modal.scss';

const PopUpModal = props => {
    const modalData = props.dataModal.imagenModal;

    return (
        <section className='f-pop-up-modal-curtain'>
            <div className="f-pop-up-modal-container">
                <span
                    className="f-close-modal-button"
                    onClick={props.closeAction}
                />
                <a
                    className="f-pop-up-card"
                    href={modalData.storeRedirection}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="f-img-pop-up"
                        src={modalData.storeLogo.file.url}
                        alt={modalData.storeLogo.description}
                    />
                </a>
            </div>
        </section>
    );
};

export default PopUpModal;
